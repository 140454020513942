import Phaser from 'phaser';

export class Player {
    constructor(id, color) {
        this.id = id;
        this.color = color;
        this.isTurn = false;
    }

    takeTurn() {

        //
    }
}

export function createPlayers(scene, playerCount) {
    const colors = getDistinctColors(playerCount);
    const players = colors.map(color => new Player(colors.indexOf(color), color))
    setPlayerHeadquarters(scene, players)
}

function getDistinctColors(numPlayers) {
    const colors = [];
    const hueStep = 360 / numPlayers;  // Divide the color wheel equally among players

    for (let i = 0; i < numPlayers; i++) {
        const hue = (i * hueStep) % 360;  // Calculate the hue for each player
        const color = Phaser.Display.Color.HSLToColor(hue / 360, 1, 0.5).color;  // Convert to Phaser color format
        colors.push(color);
    }

    return colors;
}

function getMiddleTileCoords(scene) {
    const hexagons = scene.hexagons;
    const end = hexagons[hexagons.length - 1].tile;
    const q = Math.floor(end.q / 2);
    const r = Math.floor(end.r / 2);
    return { q: q, r: r }
}

/**
 * First, determine sections of the board by dividing the board into sections, like a pie, angle based on player count.
 * For each pie-piece shaped section of the board, between certain distances from the center, pick a random tile, 
 * this random tile in the section is the start position of the player.
 * 
 * @param {*} scene 
 * @param {*} players 
 */
function setPlayerHeadquarters(scene, players) {
    const middleCoords = getMiddleTileCoords(scene);
    const sections = assignTilesToSections(scene.hexagons.map(h => h.tile), middleCoords.q, middleCoords.r, players.length)
    sections.forEach(section => {
        const sectionIndex = sections.indexOf(section);
        const filteredTiles = section.filter(tile => {
            return (tile.cubeDistanceToCenter > 5 && tile.cubeDistanceToCenter < 12)}
        );
        const min = Math.ceil(0);
        const max = Math.floor(filteredTiles.length - 1);
        const randomSectionTileIndex = Math.floor(Math.random() * (max - min + 1)) + min;
        const hqTile = filteredTiles[randomSectionTileIndex];
        hqTile.isHeadquarters = true;
        const image = scene.add.image(hqTile.startX, hqTile.startY, 'headquarters');
        image.setOrigin(0.5, 0.5);
        image.setScale(0.075);
    });
}

function assignTilesToSections(tiles, centerQ, centerR, playerCount) {
    const sections = Array.from({ length: playerCount }, () => []);  // Create empty arrays for each player section
    const angleStep = 360 / playerCount;  // Divide the circle by player count
    tiles.forEach(tile => {
        const { q, r } = tile;
        // Convert tile's coordinates (q, r) to angle relative to the center
        const angle = calculateAngle(centerQ, centerR, q, r);
        // Determine the section this tile belongs to based on the angle
        const sectionIndex = Math.floor(angle / angleStep) % playerCount;
        // Add the tile to the corresponding section
        sections[sectionIndex].push(tile);
        tile.sectionIndex = sectionIndex;
    });
    return sections;
}

// Function to calculate the angle between the center (q0, r0) and a tile (q, r)
function calculateAngle(centerQ, centerR, q, r) {
    const deltaQ = q - centerQ;
    const deltaR = r - centerR;
    // Calculate the angle in radians, then convert to degrees
    const radians = Math.atan2(deltaR, deltaQ);
    const degrees = radians * (180 / Math.PI);
    // Normalize the angle to be between 0 and 360 degrees
    return (degrees + 360) % 360;
}