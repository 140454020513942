import Phaser from 'phaser';
// import { findShortestPathAround, isHexagonReachable, findShortestPathThrough } from './pathFinding';

import { axialToCube, cubeDistance, findPathAroundObstacles, isHexagonReachable } from './pathFinding';
import { Player, createPlayers } from './players';


import { HexagonTile } from './hexagonTile';


export function createHexBoard(scene, tileRadius, tileBuffer, canvasWidth, canvasHeight) {
    scene.hexagons = [];
    const effectiveRadius = tileRadius + tileBuffer;
    const halfHeight = Math.sqrt(Math.abs(((tileRadius / 2) ** 2) - (tileRadius ** 2))) + (tileBuffer / 2);
    const effectiveHeight = (halfHeight * 2) + tileBuffer;
    /**
     * In the "horizontal" configuration of a hexagon grid, meaning the orientation of the hexagon is such that 
     * the bottom of the hexagon is an edge / line, and is not a point,
     * 
     * the first column is width of 2*radius and each subsequent column adds an additional width of 1.5 * radius
     * every second column will have minus one height, unless there is additional space at the end.
     */
    let columnsHaveSameHeight = false;
    let currentWidth = 2 * effectiveRadius;
    let actualWidth = currentWidth;
    const additionalColWidth = 1.5 * effectiveRadius;
    let columnCount = 1;
    while (currentWidth < canvasWidth) {
        const remainingWidth = canvasWidth - currentWidth;
        if (remainingWidth >= additionalColWidth) {
            currentWidth += additionalColWidth;
            columnCount++;
            actualWidth = currentWidth;
        } else {
            currentWidth = canvasWidth + 1;
        }
    }
    let rowCount = Math.floor(canvasHeight / effectiveHeight);
    let actualHeight = rowCount * effectiveHeight;
    if ((canvasHeight - (rowCount * effectiveHeight)) > halfHeight) {
        columnsHaveSameHeight = true;
        actualHeight += (effectiveHeight / 2);
    }
    const offsetX = (canvasWidth - actualWidth) / 2;
    const offsetY = (canvasHeight - actualHeight) / 2;
    let startX = 0;
    let startY = 0;
    for (let column = 0; column < columnCount; column++) {
        for (let row = 0; row < rowCount; row++) {
            if (column % 2 == 0) {
                //if it is an even column
                startX = offsetX + effectiveRadius + (column * additionalColWidth);
                startY = offsetY + effectiveHeight / 2 + (effectiveHeight * row);
                addHexagon(scene, startX, startY, row, column, tileRadius);
            } else {
                //if it is an odd column
                startX = offsetX + (effectiveRadius * 2) + (column * additionalColWidth) - effectiveRadius;
                startY = offsetY + effectiveHeight + (effectiveHeight * row);
                if (columnsHaveSameHeight) {
                    addHexagon(scene, startX, startY, row, column, tileRadius);
                } else {
                    if (row != rowCount - 1) {
                        addHexagon(scene, startX, startY, row, column, tileRadius);
                    }
                }
            }
        }
    }
    setTilesCubeDistanceToCenter(scene);
    setHexagonNeighbors(scene);
    setHexagonListeners(scene);
    setSpecialValues(scene);
}


function getHexagon(q, r, scene) {
    const foundIndex = scene.hexagons.findIndex(hexagon => {
        return hexagon.tile.q === q && hexagon.tile.r === r;
    });
    if (foundIndex) {
        return scene.hexagons[foundIndex];
    }
    return null;
}

function setHexagonNeighbors(scene) {
    // console.log("Scene", scene.hexagons)
    scene.hexagons.forEach(hexagon => {

        const q = hexagon.tile.q;
        const r = hexagon.tile.r;
        const neighborCoords = [];
        const neighbors = [];

        if (q % 2 === 0) {
            // Even column
            neighborCoords.push({ q: q + 1, r: r - 1 });         // Right
            neighborCoords.push({ q: q + 1, r: r });         // Left
            neighborCoords.push({ q: q - 1, r: r });         // Up-left
            neighborCoords.push({ q: q - 1, r: r - 1 });         // Down-right
            neighborCoords.push({ q: q, r: r - 1 });         // Down-left
            neighborCoords.push({ q: q, r: r + 1 });     // Up-right
        } else {
            // Odd column
            neighborCoords.push({ q: q + 1, r: r });         // Right
            neighborCoords.push({ q: q + 1, r: r + 1 });         // Left
            neighborCoords.push({ q: q - 1, r: r + 1 });         // Up-left
            neighborCoords.push({ q: q - 1, r: r });     // Down-right
            neighborCoords.push({ q: q, r: r - 1 });         // Down-left
            neighborCoords.push({ q: q, r: r + 1 });         // Up-right
        }
        neighborCoords.forEach(coord => {
            const foundNeighbor = scene.hexagons.find(hex => hex.tile.q === coord.q && hex.tile.r === coord.r)
            if (foundNeighbor) {
                neighbors.push(foundNeighbor.tile)
            }
        })
        hexagon.tile.setNeighbors(neighbors);
    })

}

function addHexagon(scene, startX, startY, row, column, tileRadius) {
    let hexagon = scene.add.graphics({ x: startX, y: startY });
    hexagon.tile = new HexagonTile(startX, startY, column, row, tileRadius);
    drawHexagon(hexagon);
    scene.hexagons.push(hexagon);
}
function setHexagonListeners(scene) {
    scene.hexagons.forEach(hexagon => {
        hexagon.setInteractive(new Phaser.Geom.Polygon(createHexagonPoints(hexagon.tile.radius)), Phaser.Geom.Polygon.Contains);
        hexagon.on('pointerdown', function (pointer) { onPointerDown(pointer, hexagon, scene) });
        hexagon.on('pointerover', function (pointer) { onPointerOver(pointer, hexagon, scene) });
        hexagon.on('pointerout', function (pointer) { onPointerOut(pointer, hexagon) });
    });
}

function setSpecialValues(scene) {


    // setResourceElusium(scene);

    setPlayers(scene);
    setObstacles(scene);

}


function findCenterTile(scene){
    const finalHexagon = scene.hexagons[scene.hexagons.length-1];
    const halfQ = Math.round(finalHexagon.tile.q/2);
    const halfR = Math.round(finalHexagon.tile.r/2);
    const foundTile = scene.hexagons.find(hex => hex.tile.q === halfQ && hex.tile.r === halfR);
    return foundTile.tile;
}


function setTilesCubeDistanceToCenter(scene){
    const centerTile = findCenterTile(scene);
    if(centerTile){
        scene.hexagons.forEach(hexagon => {
            const cubeCoords = centerTile.cubeCoordinates();
            const cubeDist = hexagon.tile.calculateDistanceByCube(cubeCoords);
            hexagon.tile.cubeDistanceToCenter = cubeDist;
            // var text = scene.add.text(hexagon.tile.startX, hexagon.tile.startY, cubeDist,
            //     {
            //         font: '12px Arial',
            //         fill: '#000000',
            //     });
            // text.setOrigin(0.5, 0.5)
        })
    }
}

function setPlayers(scene) {
    const playerCount = 6;
    createPlayers(scene, playerCount);

    scene.hexagons.forEach(hexagon => {
        drawHexagon(hexagon)
    })
}

let _selectedHexagon = null;
function onPointerOut(pointer, hexagon) {
    //
}
function onPointerDown(pointer, hexagon, scene) {
    if (_selectedHexagon === null) {
        selectHexagon(hexagon, scene);
    } else {
        if (_selectedHexagon.tile.equals(hexagon.tile)) {
            unselectHexagon(hexagon, scene);
        } else {
            sendReconToDestination(hexagon);
        }
    }
}

function onPointerOver(pointer, hexagon, scene) {
    // case where user has already selected a hexagon
    if (_selectedHexagon) {
        highlightPathToDestination(hexagon, scene)
    } else {
        styleCursorOver(hexagon)
    }
}

function styleCursorOver(hexagon, scene) {
    //
}

function highlightPathToDestination(hexagon, scene) {
    let travelMode = 'AROUND'; // 'AROUND' || 'THROUGH'
    if (travelMode === 'AROUND') {
        unhighlightHexagons(scene);
        if (!hexagon.tile.isObstacle()) {
            const isReachable = isHexagonReachable(_selectedHexagon.tile, hexagon.tile, scene.hexagons.map(hex => hex.tile));

            if (isReachable === true) {
                const pathHexagons = findPathToHexagon(travelMode, hexagon, scene);

                highlightHexagon(_selectedHexagon)
                pathHexagons.forEach(pathHex => {
                    scene.hexagons.forEach(hexagon => {
                        if (pathHex.equals(hexagon.tile)) {
                            highlightHexagon(hexagon)
                        }
                    })
                })
            } else {
                //
            }
        }
    } else if (travelMode === 'THROUGH') {
        // console.log("GOING THROUGHT")
        // const pathHexagons = findPathToHexagon(travelMode, hexagon, scene);
        // console.log("PATH", pathHexagons)
    }
}



function sendReconToDestination() {
    console.log("Send the recon")
}


function enableHexQRCoords(scene) {
    scene.hexagons.forEach(hexagon => {
        let displayText = hexagon.tile.q + ', ' + hexagon.tile.r;
        var text = scene.add.text(hexagon.tile.startX, hexagon.tile.startY, displayText,
            {
                font: '10px Arial',
                fill: '#000000',
            });
        text.setOrigin(0.5, 0.5);
        text.setAlpha(0.5);
    })
}
function setResourceElusium(scene) {
    // The primary objective is to get the Elusium, which exists on 1 tile and has to be retrieved
    let locationFound = false;
    while (!locationFound) {
        let num1 = Math.round(randomBellCurve(0, 44, 22, 7));
        let num2 = Math.round(randomBellCurve(0, 17, 8, 3));
        let hexAt = scene.hexagons.find(hex => hex.tile.q === num1 && hex.tile.r === num2);
        if (hexAt) {
            if (!hexAt.tile.isObstacle()) {
                locationFound = true;
                hexAt.tile.isElusium = true;
                const image = scene.add.image(hexAt.tile.startX, hexAt.tile.startY, 'resourcium');
                image.setOrigin(0.5, 0.5);
                image.setScale(0.1);
                // image.setAlpha(0.8)
            }
        }
    }
}



function setObstacles(scene) {
    let obstaclesCount = scene.hexagons.length / 5;
    let obstacleCoords = [];
    for (let i = 0; i < obstaclesCount; i++) {
        let num1 = Math.round(randomBellCurve(0, 44, 22, 7));
        let num2 = Math.round(randomBellCurve(0, 17, 8, 3));
        if (!(num1 === 0 && num2 === 0)) {
            const existingObstacleIndex = obstacleCoords.findIndex(coord => { return coord.q === num1 && coord.r === num2 });
            if (existingObstacleIndex > -1) {
                obstacleCoords[existingObstacleIndex].density++;
            } else {
                obstacleCoords.push({ q: num1, r: num2, density: 1 });
            }
        }
    }
    // all random (from bell curve) coordinates are determined, now apply the obstacle information to the hexagons
    obstacleCoords.forEach(obstacleCoord => {
        scene.hexagons.forEach(hexagon => {
            if(!hexagon.tile.isHeadquarters){
                if (obstacleCoord.q === hexagon.tile.q && obstacleCoord.r === hexagon.tile.r) {
                    hexagon.tile.density = obstacleCoord.density
                    drawHexagon(hexagon);
                }
            }
            
        })
    })
}


function findPathToHexagon(mode, hexagon, scene) {
    const hexA = _selectedHexagon.tile;
    const hexB = hexagon.tile;
    if (mode === 'AROUND') {
        const path = findPathAroundObstacles(hexA, hexB, scene);
        return path;
    } else if (mode === 'THROUGH') {
        // const path = findPathIgnoringObstacles(hexA, hexB);
        // console.log("path la", path)
        // return path;
        return []
    }
    return [];
}


function selectHexagon(hexagon, scene) {
    if (!hexagon.tile.isObstacle()) {
        _selectedHexagon = hexagon;
        hexagon.tile.isSelected = true;
        drawHexagon(hexagon);
    } else {
        //
    }

    
}





function unselectHexagon(hexagon, scene) {
    _selectedHexagon = null;
    hexagon.tile.isSelected = false;
    drawHexagon(hexagon);

    unhighlightHexagons(scene);
    scene.hexagons.forEach(hexagon => {
        hexagon.tile.resetTile();
    });
}


function highlightHexagon(hexagon) {
    hexagon.tile.isHighlighted = true;
    drawHexagon(hexagon);
}

function unhighlightHexagons(scene) {
    scene.hexagons.forEach(hexagon => {
        hexagon.tile.isHighlighted = false;
        if (!hexagon.tile.isObstacle()) {
            // hexagon.clear();
            drawHexagon(hexagon);
        }
    });
}


const randomColor = Phaser.Display.Color.RandomRGB().color

const randomColorIndex1 = Phaser.Display.Color.RandomRGB().color;
const randomColorIndex2 = Phaser.Display.Color.RandomRGB().color;
const randomColorIndex3 = Phaser.Display.Color.RandomRGB().color;
const randomColorIndex4 = Phaser.Display.Color.RandomRGB().color;
const randomColorIndex5 = Phaser.Display.Color.RandomRGB().color;
const randomColorIndex6 = Phaser.Display.Color.RandomRGB().color;


function drawHexagon(hexagon) {
    hexagon.clear();
    let fillColor = Phaser.Display.Color.GetColor(0, 0, 0);
    let fillAlpha = 0.02;
    let lineColor = Phaser.Display.Color.GetColor(0, 0, 0);
    let lineAlpha = 0.15;
    let lineWidth = 1;
    if (hexagon.tile.pointerIsOver) {
        lineAlpha = 4;
    }
    if (hexagon.tile.isSelected) {
        lineAlpha = 1;
        lineWidth = 3;
    }
    if (hexagon.tile.isHighlighted) {
        fillColor = randomColor;
        fillAlpha = 0.5;
        lineColor = Phaser.Display.Color.GetColor(0, 0, 0)
        lineAlpha = 1;
    }
    if (hexagon.tile.isObstacle()) {
        fillColor = Phaser.Display.Color.GetColor(50, 110, 110);
        const densityFill = (hexagon.tile.density * 0.2)
        fillAlpha = densityFill > 1 ? 1 : densityFill;
        lineAlpha = 0;
    }


    if(hexagon.tile.sectionIndex !== null && hexagon.tile.cubeDistanceToCenter > 5 && hexagon.tile.cubeDistanceToCenter < 13){
        // console.log("SECTION INDEX", hexagon.tile.sectionIndex)
        const index = hexagon.tile.sectionIndex;
        // if(index === 0){
        //     fillColor = randomColorIndex1;
        // }else if(index === 1){
        //     fillColor = randomColorIndex2;
        // }else if(index === 2){
        //     fillColor = randomColorIndex3;
        // }else if(index === 3){
        //     fillColor = randomColorIndex4;
        // }else if(index === 4){
        //     fillColor = randomColorIndex5;
        // }else if(index === 5){
        //     fillColor = randomColorIndex6;
        // }
        // fillAlpha = 1;
    }


    const points = createHexagonPoints(hexagon.tile.radius);
    hexagon.fillStyle(fillColor, fillAlpha);
    hexagon.lineStyle(lineWidth, lineColor, lineAlpha);
    hexagon.beginPath();
    hexagon.moveTo(points[0].x, points[0].y);
    for (let i = 1; i < points.length; i++) {
        hexagon.lineTo(points[i].x, points[i].y);
    }
    hexagon.closePath();
    hexagon.fillPath();
    hexagon.strokePath();
}

// Function to create points for a hexagon
function createHexagonPoints(radius) {
    const points = [];
    for (let i = 0; i < 6; i++) {
        const angle = Phaser.Math.DegToRad(60 * i);
        const x = radius * Math.cos(angle);
        const y = radius * Math.sin(angle);
        points.push({ x: x, y: y });
    }
    return points;
}


export function randomBellCurve(min, max, mean, stdDev) {
    // Generate two uniform random numbers
    let u1 = Math.random();
    let u2 = Math.random();
    // Box-Muller transform to get a normally distributed number
    let randStdNormal = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2.0 * Math.PI * u2);
    // Scale by standard deviation and mean
    let randNormal = mean + stdDev * randStdNormal;
    // Clamp the result between the min and max
    return Math.max(min, Math.min(max, randNormal));
}
