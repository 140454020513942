// src/router/index.ts
import { createRouter, createWebHistory } from 'vue-router'
import CatPage from '../components/CatPage.vue'
import DogPage from '../components/DogPage.vue'
import GoatPage from '../components/GoatPage.vue'
import MinefinderGame from '../components/Minefinder/MinefinderGame.vue'
import LinkerGame from '../components/Linker/LinkerGame.vue'

const routes = [
  { path: '/cat', name: 'CatPage', component: CatPage },
  { path: '/dog', name: 'DogPage', component: DogPage },
  { path: '/goat', name: 'GoatPage', component: GoatPage },
  { path: '/minefinder', name: 'Minefinder', component: MinefinderGame },
  { path: '/linker', name: 'Linker', component: LinkerGame },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
