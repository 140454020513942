export function findPathAroundObstacles(startTile, goalTile, scene) {

    let openSet = [];
    let closedSet = new Set();

    startTile.g = 0;
    startTile.f = heuristic(startTile, goalTile, scene, startTile);
    openSet.push(startTile);

    while (openSet.length > 0) {
        // Get the tile in openSet with the lowest f score
        let currentTile = openSet.reduce((prev, curr) => (prev.f < curr.f ? prev : curr));

        // If the goal is reached
        if (currentTile === goalTile) {
            return reconstructPath(currentTile);
        }

        // Remove currentTile from openSet and add to closedSet
        openSet = openSet.filter(tile => tile !== currentTile);
        closedSet.add(currentTile);

        // Loop through neighbors of currentTile
        for (let neighbor of currentTile.neighbors) {
            // Skip if neighbor has a density > 0 (i.e., it's an obstacle)
            if (neighbor.density > 0 || closedSet.has(neighbor)) {
                continue;
            }

            let tentativeG = currentTile.g + 1;  // Assuming uniform cost between tiles

            if (!openSet.includes(neighbor)) {
                // Discover a new tile
                openSet.push(neighbor);
            } else if (tentativeG >= neighbor.g) {
                continue;  // This is not a better path
            }

            // This path is the best so far
            neighbor.parent = currentTile;
            neighbor.g = tentativeG;
            neighbor.f = neighbor.g + heuristic(neighbor, goalTile, scene, startTile);
        }
    }

    // No valid path found
    return null;
}

function heuristic(tileA, tileB) {
    const cubeCoordsB = tileB.cubeCoordinates();
    return tileA.calculateDistanceByCube(cubeCoordsB);
}

function reconstructPath(currentTile) {
    let path = [];
    while (currentTile.parent != null) {
        path.push(currentTile);
        currentTile = currentTile.parent;
    }
    return path.reverse();  // Return the path from start to goal
}



export function isHexagonReachable(selectedTile, targetTile, allTiles) {
    // Function to check if a given tile is an obstacle (density > 0)
    function isObstacle(tile) {
        return tile.density > 0;
    }

    // BFS (Breadth-First Search) or DFS (Depth-First Search) to find if there is a path
    function searchForPath(startTile, endTile) {
        let queue = [startTile];
        let visited = new Set();

        // Add the start tile to the visited set
        visited.add(`${startTile.column},${startTile.row}`);

        while (queue.length > 0) {
            let currentTile = queue.shift();

            // If we've reached the target tile, return true
            if (currentTile === endTile) {
                return true;
            }

            // Loop through the neighbors of the current tile
            for (let neighbor of currentTile.neighbors) {
                let neighborTile = allTiles.find(
                    (tile) => tile.column === neighbor.q && tile.row === neighbor.r
                );

                // If the neighbor is not an obstacle and hasn't been visited, add to the queue
                if (neighborTile && !isObstacle(neighborTile) && !visited.has(`${neighborTile.column},${neighborTile.row}`)) {
                    visited.add(`${neighborTile.column},${neighborTile.row}`);
                    queue.push(neighborTile);
                }
            }
        }

        // If we exhaust the search and don't find the target tile, it's unreachable
        return false;
    }

    // Return the result of the search
    return searchForPath(selectedTile, targetTile);
}