<template>
  <div>
    <h1>Goat Page</h1>
    <img src="/assets/images/watermelon.png" alt="watermelon">
    <p>Goats eat all the foliage</p>
  </div>
</template>

<script>
export default {
  name: 'GoatPage'
}
</script>
