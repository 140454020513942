<template>
  <div>
    <h1>Dog Page</h1>
    <img src="/assets/images/mango.png" alt="Dog">
    <p>Dogs are loyal</p>
  </div>
</template>

<script>
export default {
  name: 'DogPage'
}
</script>
