import Phaser from 'phaser';
import { createHexBoard } from "./createHexBoard";



const NAV_HEIGHT = 40;
const CANVAS_WIDTH = window.innerWidth;
const CANVAS_HEIGHT = window.innerHeight - NAV_HEIGHT;

const TILE_RADIUS = 24;
const TILE_BUFFER = 3;


// Function to initialize the Phaser game
export function initializePhaser(container) {
    return new Phaser.Game({
        type: Phaser.AUTO,
        width: CANVAS_WIDTH,
        height: CANVAS_HEIGHT,
        scene: GameScene,
        parent: container,
        audio: {
            disableWebAudio: false,
        },
        autoFocus: true,
        input: {
            windowEvents: false // Disable default input window focus/blur behavior
        },
        backgroundColor: 0xffffff,
    });
}

export const gameEventEmitter = new Phaser.Events.EventEmitter();

class GameScene extends Phaser.Scene {
    constructor() {
        super({ key: 'GameScene' });
    }

    preload() {
        this.load.audio('boop1', 'assets/sounds/boop1.mp3');
        this.load.audio('bing1', 'assets/sounds/bing1.mp3');
        this.load.audio('gentle_bong1', 'assets/sounds/gentle_bong1.mp3');
        this.load.audio('gentle_bong2', 'assets/sounds/gentle_bong2.mp3');
        this.load.audio('gentle_bong3', 'assets/sounds/gentle_bong3.mp3');
        this.load.audio('bong1', 'assets/sounds/bong1.mp3');
        this.load.audio('bong2', 'assets/sounds/bong2.mp3');
        this.load.audio('bong3', 'assets/sounds/bong3.mp3');
        this.load.audio('zoop', 'assets/sounds/zoop.mp3');
        this.load.audio('explosion', 'assets/sounds/explosion4.mp3')


        this.load.image('bomb', 'assets/images/bomb2.png');
        this.load.image('mango', 'assets/images/mango.png');
        this.load.image('watermelon', 'assets/images/watermelon.png');
        this.load.image('coin', 'assets/images/coin.png');

        this.load.image('recon', 'assets/images/recon.png');
        this.load.image('elusium', 'assets/images/elusium.png');
        this.load.image('elusivium', 'assets/images/elusivium.png');
        this.load.image('resourcium', 'assets/images/resourcium.png');

        this.load.image('headquarters', 'assets/images/headquarters3.webp')
        this.load.image('ore', 'assets/images/ore.webp')
    }

    create() {

        createHexBoard(this, TILE_RADIUS, TILE_BUFFER, CANVAS_WIDTH, CANVAS_HEIGHT);
        // this.cameras.main.setBounds(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT); // Set camera bounds to the size of your game board
        // this.cameras.main.startFollow(null); // Stop following any object
        let isDragging = false;
        let dragStartX = 0;
        let dragStartY = 0;

        // this.input.on('pointerdown', (pointer) => {
        //     isDragging = true;
        //     dragStartX = pointer.worldX;
        //     dragStartY = pointer.worldY;
        // });

        // this.input.on('pointermove', (pointer) => {
        //     if (isDragging) {
        //         const deltaX = dragStartX - pointer.worldX;
        //         const deltaY = dragStartY - pointer.worldY;

        //         this.cameras.main.scrollX += deltaX;
        //         this.cameras.main.scrollY += deltaY;

        //         dragStartX = pointer.worldX;
        //         dragStartY = pointer.worldY;
        //     }
        // });

        // this.input.on('pointerup', () => {
        //     isDragging = false;
        // });

        this.input.on('wheel', (pointer, gameObjects, deltaX, deltaY, deltaZ) => {
            let zoomFactor = this.cameras.main.zoom - deltaY * 0.001;
            this.cameras.main.setZoom(Phaser.Math.Clamp(zoomFactor, 0.5, 2));
        });


    }

    update() {
        // Game update logic here
    }


}


function checkVictoryConditions(scene) {
    console.log("Check victory conditions")
}

function gameOverLoss(scene) {

    gameEventEmitter.emit('game-over-defeat');
}

function gameOverVictory(scene) {
    gameEventEmitter.emit('game-over-victory');
}

