<template>
  <div>
    <h1>Cat Page</h1>
    <img src="/assets/images/coin.png" alt="Dog">
    <p>Cats are small, carnivorous mammals that are often kept as pets.</p>
  </div>
</template>

<script>
export default {
  name: 'CatPage'
}
</script>
